<template>
  <div
    class="carousel"
    :class="classes"
    tabindex="0"
    data-id="::carousel::"
    @mouseover="onMouseEnter"
    @mouseleave="onMouseLeave"
    @keyup="handleKeyboardEvent">
    <div
      v-if="isFaIcon"
      class="carousel__icon-wrapper">
      <a-icon :icon="icon" />
    </div>

    <div
      v-if="isIconImage"
      class="carousel__icon-wrapper">
      <img
        :src="icon?.[0].url"
        :alt="icon?.[0].altText">
    </div>

    <div
      v-if="slides && slides.length > 0"
      class="carousel__wrapper"
      :style="carouselStyle">
      <slide-transition
        v-for="(slide, index) in slides as ElementItem<ImageCarouselSlide>[]"
        :key="index">
        <div v-if="data.slideIndex === index">
          <div class="carousel__slide">
            <img
              v-if="slide.properties.image?.[0]"
              :src="`${slide.properties.image?.[0].url}?height=720`"
              :alt="slide.properties.image?.[0].properties.altText"
              class="carousel__image">

            <div
              class="carousel__container"
              :style="slideStyle">
              <div :class="['carousel__content', { 'carousel__content--with-icon': hasIcon }]">
                <div class="carousel__text-content">
                  <component
                    :is="index > 0 ? 'strong' : 'h1'"
                    v-if="heading"
                    class="carousel__heading">
                    {{ heading }}
                  </component>
                  <rich-text
                    class="carousel__description"
                    :style="dropShadowStyle"
                    :content="description" />
                </div>

                <div
                  v-if="slides && slides.length > 1 && slides.length < maxNavDots"
                  class="carousel__navigator">
                  <button
                    class="carousel__navigator-icon"
                    :tabindex="1"
                    @keyup="handleKeyboardEvent"
                    @click="clickedLeft">
                    <a-icon
                      aria-hidden="true"
                      icon="fa:solid:chevron-left" />
                  </button>

                  <div
                    v-for="navIndex in slides.length"
                    :key="navIndex"
                    :tabindex="navIndex"
                    class="carousel__navigator-slide-dot"
                    :class="{
                      'carousel__navigator-slide-dot--selected': navIndex - 1 === data.slideIndex,
                    }"
                    @click="selectSlide(navIndex - 1)" />

                  <button
                    class="carousel__navigator-icon"
                    :tabindex="2"
                    @keyup="handleKeyboardEvent"
                    @click="clickedRight">
                    <a-icon
                      aria-hidden="true"
                      icon="fa:solid:chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide-transition>
    </div>

    <a-progress-ring
      v-if="autoPlay && slides && slides.length > 1"
      class="carousel__progress"
      :class="'carousel__progress--' + internalImageAlign"
      variant="primary"
      thickness="3px"
      role="button"
      size="xs"
      :aria-label="progressPaused ? 'Play' : 'Pause'"
      :percentage="progressPercent"
      show-percentage
      @click="togglePlayback">
      <template #default>
        <a-icon
          v-if="progressPaused"
          aria-hidden="true"
          icon="fa:solid:pause" />
        <span v-else />
      </template>
    </a-progress-ring>
  </div>
</template>

<script lang="ts" setup>
import anime from "animejs";
import type { ElementItem } from "@edco/apollo";
import type { ImageCarouselSlide } from "~/models/generated/ImageCarouselSlide";

const keyboardEvent = {
  keyCode: {
    SPACE: 32,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    ENTER: 13,
    SHIFT: 16,
    BACKSPACE: 8,
    CTRL: 7,
    TAB: 9,
    DELETE: 46,
  },
};

const props = withDefaults( defineProps<{
  heading: string,
  headingStyle?: string,
  headingSize?: string,
  buttonStyle?: string,
  slides: ImageCarouselSlideStub[],
  buttonClass?: string,
  gradientColor?: string,
  fullWidth?: boolean,
  height?: string,
  maxNavDots?: number,
  autoPlay?: boolean,
  autoPlayDuration?: number,
  imageAlign?: string,
  icon: ImageStub | string,
  description?: string | null,
}>(), {
  headingStyle: "bold",
  headingSize: "h1",
  buttonStyle: "white",
  gradientColor: "#f2f2f2",
  fullWidth: true,
  height: "300px",
  maxNavDots: 7,
  autoPlay: true,
  autoPlayDuration: 5000,
  imageAlign: "right",
  buttonClass: undefined,
  description: undefined,
} );

const data = reactive( {
  slideIndex: 0,
  mouseOverCarousel: false,
  autoPlayCarousel: props.autoPlay,
  modalTitle: null,
  modalLink: null,
} );

const animationInstance = ref<anime.AnimeInstance>();

const progress = {
  percentage: 0,
};

const progressPaused = ref( false );
const progressPercent = ref( 0 );

const isFaIcon = computed( () => typeof props.icon === "string" );

const isIconImage = computed( () => typeof Array.isArray( props.icon ) && !!props.icon?.[0].url );

const hasIcon = computed( () => {
  let result = false;

  if ( props.icon && isFaIcon ) {
    result = true;
  } else if ( typeof props.icon === "object" ) {
    result = !!props.icon?.url;
  }
  return result;
} );

const internalImageAlign = computed( () => props.imageAlign.trim().toLowerCase() );

const slideStyle = computed( () => ( {
  "background-image": `linear-gradient( to ${internalImageAlign.value}, ${props.gradientColor} 40%, rgba( 0, 0, 0, 0 ) 70% )`,
  "min-height": props.height,
} ) );

const classes = computed( () => ( {
  "carousel--full-width": props.fullWidth,
} ) );

const dropShadowStyle = computed( () => ( {
  filter: `drop-shadow( 0 0 10px ${props.gradientColor} )`,
} ) );

const carouselStyle = computed( () => ( {
  "background-color": `${props.gradientColor}`,
} ) );

function selectSlide ( index : number ) {
  data.slideIndex = index;
}

function slideLeft () {
  if ( data.slideIndex - 1 < 0 ) {
    selectSlide( props.slides.length - 1 );
  } else {
    selectSlide( data.slideIndex - 1 );
  }
}

function slideRight () {
  if ( data.slideIndex + 1 > props.slides.length - 1 ) {
    selectSlide( 0 );
  } else {
    selectSlide( data.slideIndex + 1 );
  }
}

function play () {
  animationInstance.value?.play();
  progressPaused.value = false;
}

function pause () {
  animationInstance.value?.pause();
  progressPaused.value = true;
}

function togglePlayback () {
  data.autoPlayCarousel = !data.autoPlayCarousel;

  if ( data.autoPlayCarousel ) {
    play();
  } else {
    pause();
  }
}

function createAnimation () {
  if ( process.client ) {
    animationInstance.value = anime( {
      targets: progress,
      percentage: 100,
      easing: "linear",
      duration: 6000,
      loop: true,
      update: () => {
        progressPercent.value = progress.percentage;
      },
      loopComplete: () => {
        slideRight();
      },
    } );
  }
}

function resetInterval () {
  animationInstance.value?.restart();

  if ( data.mouseOverCarousel || data.autoPlayCarousel === false ) {
    pause();
  }
}

function clickedLeft () {
  slideLeft();
  resetInterval();
}

function clickedRight () {
  slideRight();
  resetInterval();
}

function onMouseEnter () {
  data.mouseOverCarousel = true;

  if ( data.autoPlayCarousel ) {
    pause();
  }
}

function onMouseLeave () {
  data.mouseOverCarousel = false;

  if ( data.autoPlayCarousel ) {
    play();
  }
}

function handleKeyboardEvent ( evt : KeyboardEvent ) {
  const keycode = evt.keyCode || evt.which; // also for cross-browser compatible
  const isCarouselEvent = document.activeElement?.getAttribute( "data-id" ) === "::carousel::";

  switch ( keycode ) {
    case keyboardEvent.keyCode.LEFT:
      clickedLeft();
      break;
    case keyboardEvent.keyCode.RIGHT:
      clickedRight();
      break;
    case keyboardEvent.keyCode.SPACE:
    case keyboardEvent.keyCode.ENTER: {
      // to prevent collision with progress ring MouseEvent
      if ( isCarouselEvent ) {
        togglePlayback();
      }
      break;
    }
    default:
      console.info( keycode );
      break;
  }
}

function preloadImages () {
  if ( props.slides && props.slides.length ) {
    props.slides.forEach( ( slide : ElementItem<ImageCarouselSlide> ) => {
      const image = slide.properties.image?.[0];
      if ( image?.url ) {
        const img = new Image();

        img.src = image.url;
        img.srcset = `${image.url}?height=720 1x, ${image.url}?height=1440 2x`;
      }
    } );
  }
}

watch( () => props.autoPlay, ( val ) => {
  if ( val ) {
    createAnimation();
  } else if ( animationInstance.value ) {
    pause();
  }
}, { immediate: true } );

onMounted( () => {
  window.addEventListener( "focus", resetInterval );
  preloadImages();
} );

onUnmounted( () => {
  pause();
  window.removeEventListener( "focus", resetInterval );
} );
</script>

<style lang="scss" scoped>
$carousel-container-padding-x: 3rem;
$carousel-content-y-padding: 40px;
$icon-dim: 70px;
$icon-center-offset: 10px;

.carousel {
  max-width: 1500px;
  margin: 0 auto;
  position: relative;

  &__wrapper {
    z-index: 2;
    position: relative;
  }

  &--full-width {
    max-width: inherit;
  }

  &__icon-wrapper {
    position: absolute;
    top: calc( ($carousel-content-y-padding / 2) + $icon-center-offset );
    left: $carousel-container-padding-x;
    height: $icon-dim;
    width: $icon-dim;
    font-size: $icon-dim;
    line-height: 1;
    z-index: 100;

    > * {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__main-heading {
    color: var( --color-primary-500 );
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1.25rem;

    @media (width <= 992px) {
      font-size: 1.19rem;
      margin-bottom: 10px;
    }
  }

  &__slide {
    background-size: cover;
    display: flex;
    width: 100%;

    @media (width <= 991px) {
      background-color: rgb( 255 255 255 / 70% );
      background-image: none !important;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    position: absolute;
  }

  @media (width <= 991px) {
    &--right {
      .carousel__image {
        object-position: 75% 50%;
      }
    }

    &--left {
      .carousel__image {
        object-position: 25% 50%;
      }
    }
  }

  &__container {
    display: flex;
    padding: 0 $carousel-container-padding-x;
    width: 100%;
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-bottom: 20px;

    @media (width <= 992px) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 40%;
    padding: $carousel-content-y-padding 0;

    &--with-icon {
      padding: calc( $carousel-content-y-padding + $icon-dim ) 0
        calc( $carousel-content-y-padding + $icon-center-offset );
    }

    @media (width <= 992px) {
      flex-basis: 100%;
    }
  }

  &__heading {
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 1.25rem;
    font-weight: 500;
    color: #333;
    font-size: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @media (width <= 992px) {
      margin-bottom: 0.625rem;
    }

    &--normal {
      font-weight: 400;
    }

    &--bold {
      font-weight: 500;
    }
  }

  &__description {
    font-size: 1.13rem;
    line-height: 1.5rem;
    margin-bottom: 1.25rem;

    @media (width <= 992px) {
      font-size: 1.06rem;
      margin-bottom: 0.625rem;
    }

    h3 {
      margin-bottom: 1.25rem !important;
    }
  }

  &__button {
    text-decoration: none;
    text-transform: uppercase;
    box-shadow: 0 0 10px 0 rgb( 0 0 0 / 20% );

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: 1px auto #000;
    }

    &--red {
      color: #fff !important;
      background-color: var( --color-primary-500 );

      &:hover {
        border: 2px solid #fff;
      }
    }

    &--white {
      color: var( --color-primary-500 ) !important;
      background-color: #fff;

      &:hover {
        border: 2px solid var( --color-primary-500 );
      }
    }

    &--link {
      color: var( --color-primary-500 ) !important;
      box-shadow: none;
      font-weight: 400;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__timer {
    position: absolute;
  }

  &__navigator {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-icon {
      font-size: 1.13rem;
      border: 0;
      background: none;
      min-height: 30px;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: #000 auto 1px;
      }
    }

    &-slide-dot {
      width: 20px;
      height: 20px;
      border: 2px solid lightgray;
      border-radius: 10px;
      margin: 0 10px;
      transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

      &--selected {
        background-color: #333;
        border-color: #333;
      }

      &:focus {
        outline: #000 auto 1px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__progress {
    position: absolute !important;
    margin: 10px;
    top: 0;
    z-index: 10000;
    cursor: pointer;
    font-size: 1rem;
    color: var( --color-primary-500 );

    &--right {
      right: 0;
    }

    &--left {
      left: 0;
    }
  }
}
</style>
